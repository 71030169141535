import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';
import { FC } from 'react';
import { mergeSx } from '~/utils/mui';

export interface LogoProps extends SvgIconProps {
    variant?: 'plus' | 'square';
    hideFirst?: boolean;
}

const Logo: FC<LogoProps> = ({ variant, hideFirst, sx, ...props }: LogoProps) => {
    const theme = useTheme();
    const localSx = {
        color: theme.palette.mode === 'light' ? theme.palette.secondary.main : theme.palette.common.white,
        height: 34,
        width: 'auto',
    };
    if (variant === 'plus') {
        return (
            <SvgIcon viewBox="0 0 141 79" {...props} sx={mergeSx(localSx, sx)}>
                <path
                    d="M61.6269 7.61128C53.6157 1.91648 43.3918 -0.443218 32.8923 1.1438C12.0833 4.28913 -2.99626 21.8844 0.506537 45.0613C4.00864 68.2337 23.6398 80.7471 44.4483 77.6011C49.3907 76.8543 54.0009 75.2579 58.1356 72.9847L45.6508 55.6337C43.4987 56.4895 41.1389 56.9567 38.6625 56.9567C29.1563 56.9542 21.152 50.0116 21.1541 39.2749C21.1562 28.8626 29.1629 21.7608 38.6694 21.7629C48.3456 21.765 56.3499 28.8693 56.3478 39.2825C56.3464 44.1567 54.6386 48.1928 51.9301 51.2087L71.8789 77.1512H77.4733V1.50675H64.5155L61.6269 7.61128"
                    fill="currentColor"
                />
                <path
                    d="M111.435 64.2268V19.4177H124.999V64.2268H111.435ZM95.8125 48.6042V35.0403H140.622V48.6042H95.8125Z"
                    fill="currentColor"
                />
            </SvgIcon>
        );
    }

    if (variant === 'square') {
        return (
            <SvgIcon viewBox="0 0 71 71" {...props} sx={mergeSx(localSx, sx)}>
                <path
                    d="M55.8578 6.58831C48.631 1.45117 39.4083 -0.677457 29.937 0.754152C11.1656 3.59148 -2.43723 19.4638 0.722559 40.371C3.88172 61.2743 21.5905 72.5624 40.3614 69.7244C44.8198 69.0508 48.9785 67.6107 52.7083 65.5601L41.4461 49.9082C39.5048 50.6802 37.376 51.1016 35.1422 51.1016C26.5668 51.0994 19.3464 44.8366 19.3482 35.1513C19.3501 25.7586 26.5728 19.3523 35.1484 19.3542C43.877 19.356 51.0975 25.7646 51.0956 35.1581C51.0944 39.555 49.5538 43.1959 47.1105 45.9165L65.1058 69.3186H70.1524V1.08156H58.4635L55.8578 6.58831"
                    fill="currentColor"
                />
            </SvgIcon>
        );
    }

    return (
        <SvgIcon viewBox="0 0 297 94" {...props} sx={mergeSx(localSx, sx)}>
            {!hideFirst && (
                <path
                    d="M55.5922 29.9282C48.3654 24.791 39.1427 22.6624 29.6714 24.094C10.9 26.9313 -2.70285 42.8036 0.456934 63.7109C3.6161 84.6142 21.3249 95.9022 40.0957 93.0643C44.5542 92.3906 48.7129 90.9506 52.4427 88.8999L41.1805 73.248C39.2391 74.02 37.1104 74.4415 34.8765 74.4415C26.3012 74.4392 19.0807 68.1765 19.0826 58.4911C19.0845 49.0985 26.3072 42.6921 34.8828 42.694C43.6114 42.6959 50.8319 49.1045 50.83 58.498C50.8287 62.8949 49.2882 66.5357 46.8449 69.2563L64.8402 92.6585H69.8868V24.4214H58.1978L55.5922 29.9282"
                    fill="currentColor"
                />
            )}
            <path
                d="M181.712 66.8781H181.004L167.727 24.5656H146.835L171.621 92.5488H190.741L215.704 24.5656H194.813L181.712 66.8781Z"
                fill="currentColor"
            />
            <path d="M84.5164 92.5488H104.876V1.90447H84.5164V92.5488Z" fill="currentColor" />
            <path d="M117.624 92.5488H137.982V1.90447H117.624V92.5488Z" fill="currentColor" />

            <path
                d="M235.534 0.665693C229.514 0.665693 224.557 5.26804 224.557 10.7566C224.557 16.2452 229.514 20.8481 235.534 20.8481C241.553 20.8481 246.333 16.2452 246.333 10.7566C246.333 5.26804 241.553 0.665693 235.534 0.665693Z"
                fill="currentColor"
            />
            <path d="M225.266 92.5488H245.625V24.5656H225.266V92.5488Z" fill="currentColor" />
            <path
                d="M293.779 76.4383C292.54 76.7925 290.77 76.9699 289.531 76.9699C286.167 76.9699 283.689 76.261 283.689 71.1276V38.7291H294.31V24.5655H283.689V10.0486H263.328V24.5655H255.716V38.7291H263.328V73.6057C263.328 85.6439 272.003 93.4341 282.98 93.4341C290.417 93.4341 295.55 92.0181 296.789 91.4868L293.779 76.4383"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default Logo;
